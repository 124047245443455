/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Property decorator that lets you have a side-effect for assignment. Syntactic
 * sugar, where this:
 *
 * ```
 * __foo: T;
 * @Input()
 * get foo() {
 *   return this.__foo;
 * }
 * set foo(value: T) {
 *   this.__foo = value;
 *   send(value);
 * }
 *
 * readonly foo$ = new Subject<T>();
 * ```
 *
 * Can instead be written as this:
 *
 * ```
 * @Input()
 * @OnChange((self, v) => self.foo$.next(v))
 * foo T;
 *
 * readonly foo$ = new Subject<T>();
 * ```
 *
 * @param send function to call when the property is set.
 */
export function OnChange(send: (self: any, newValue: any) => void) {
  return (prototype: any, propertyName: string) => {
    const hiddenPropertyName = `__${propertyName}`;

    // Replace the property with an accessor (getter/setter pair).
    Object.defineProperty(prototype, propertyName, {
      get() {
        // Return the hidden subject.
        return this[hiddenPropertyName];
      },

      set(newValue: any) {
        if (!this[hiddenPropertyName]) {
          // The first time the value is set, define the hidden property. Make
          // it non-enumerable to hide it from Object.keys() etc.
          Object.defineProperty(this, hiddenPropertyName, {
            writable: true,
            enumerable: false,
            configurable: false,
          });
        }

        // Store the value as normal...
        this[hiddenPropertyName] = newValue;

        // ... and then send it to the target.
        send(this, newValue);
      },

      enumerable: true,
      configurable: false,
    });
  };
}
